import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, getBottomNavigationActionUtilityClass, Button } from '@mui/material';
// utils
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
// components
import ListIcon from '@mui/icons-material/List';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { Component, useContext, useState, useEffect }  from 'react';
import Divider from "@mui/material/Divider";
import { alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { FormLabel, Typography } from '@mui/material/';
import AuthContext from '../../context/AuthContext';
//import { set } from 'mongoose';
// ----------------------------------------------------------------------

import useAxios from '../../api/useAxiosPrivate';






export function bgBlur(props) {
  const color =  '#1565C0';
  const blur =  6;
  const opacity = 0.8;


  return {
    backdropFilter: `blur(${blur}px)`,
    WebkitBackdropFilter: `blur(${blur}px)`,
    backgroundColor: alpha(color, opacity),
  };
}




const NAV_WIDTH = 280;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const axiosPrivate = useAxios();
  let {user, logoutUser} = useContext(AuthContext)
  //console.log(user.premium)
  const [premStatus, setPremStatus] = useState(null);
  const isSmallScreen = useMediaQuery('(max-width:900px)');
  const isMiniScreen = useMediaQuery('(max-width:700px)');
  useEffect(() => {

    const fetchPrivateData = async () => {
      const config = {
        header: {
          "Content-Type": "application/json",
        },
      };
      try {

        const { data } = await axiosPrivate.get("/api/auth/authorize" , config, { withCredentials: true });   
        //console.log(data)
        setPremStatus(data.status)

      } catch (error) {

        setPremStatus(false)
      }

    };



     fetchPrivateData()
      //console.log(premStatus)
    }, [user]); 



    

    




  return (
    <StyledRoot>
      <StyledToolbar>
        <ListIcon         
         onClick={onOpenNav}
          sx={{
            mr: 1,
            color: 'text.primary',
            display: { lg: 'none' },
            
          }} ></ListIcon>
        <Searchbar  />

        <Box sx={{ flexGrow: 0.5 }} />


        {premStatus === false  && (
  <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1 }}>
    {!isMiniScreen && (
    <div>
      <Typography
        component={Link}
        to="/pricing"
        style={{
          color: "#FFC107",
          fontFamily: "Roboto Mono",
          textTransform: "none",
          fontSize: "30px",
          fontStyle: "none",
          fontWeight: "bold",
        }}
      >

        <WorkspacePremiumIcon />

      </Typography>
      
    </div>
     )}
    {/* Centered Text */}
    <div style={{ flexGrow: 1, textAlign: "center" }}>
    <Typography
      style={{
        color: '#FFFFFF',
        fontFamily: 'Roboto Mono',
        textTransform: 'none',
        fontSize: isMiniScreen ? '13px' : '20px', // Conditionally set the fontSize property
      }}
    >
      ChartingFS <strong>Premium</strong>
    </Typography>
    {isMiniScreen && (
        
        <Button
        component={Link}
        to="/pricing"
        sx={{
          marginTop: "5px",
          color: "#FFFFFF", // White text color
          backgroundColor: "#333333", // Background color
          fontFamily: "Roboto Mono", // Set font to Roboto Mono
          fontSize: isMiniScreen ? '7px' : '12px', // Conditionally set the fontSize property
          textTransform: "none", // Prevent text transformation
          "&:hover": {
            backgroundColor: "#444444", // Background color on hover
          },
        }}
      >
        Start 14-Day Free Trial
      </Button>
      )}

      {!isSmallScreen && (
        <Typography
          style={{
            color: '#FFFFFF',
            fontFamily: 'Roboto Mono',
            textTransform: 'none',
            fontSize: '13px',
            whiteSpace: 'pre-wrap',
            width: '400px', // Adjust the width as needed
          }}
        >
          See all Graphs, Compare data, Download data and more!
        </Typography>
      )}
    </div>
    <Divider />
    <Divider />
    <Divider />
    {!isMiniScreen && (
    <Button
      component={Link}
      to="/pricing"
      sx={{
        color: "#FFFFFF", // White text color
        backgroundColor: "#333333", // Background color
        fontFamily: "Roboto Mono", // Set font to Roboto Mono
        fontSize: isMiniScreen ? '3px' : '12px', // Conditionally set the fontSize property
        textTransform: "none", // Prevent text transformation
        "&:hover": {
          backgroundColor: "#444444", // Background color on hover
        },
      }}
    >
      Start 14-Day Free Trial
    </Button>
      )}
    <Box sx={{ flexGrow: 1 }} />
  </Stack>
)}



<Box sx={{ flexGrow: 0.5 }} />
<div>
    
    {user ? (
      <div>
        <AccountPopover />
      </div>
    ) : (
      <Typography
        component={Link}
        to="/login"
        style={{
          color: "#FFFFFF",
          fontFamily: "Roboto Mono",
          textTransform: "none",
          fontSize: isMiniScreen ? '10px' : '20px', // Conditionally set the fontSize property
          textDecoration: "none", // To remove underlining
          fontWeight: "bold",
        }}
      >
        Log In
      </Typography>
    )}
  </div>
      </StyledToolbar>
      <Divider/>
    </StyledRoot>
  );
}
