
import React, { useState, useEffect, useRef, useCallback , useContext} from 'react';


import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import { Button } from '@mui/material';
import Grow from '@mui/material/Grow';
import Grid from '@mui/material/Grid';

import { useNavigate } from "react-router-dom";
import abbreviateNumber from '../utils/abbreviateNumber';
import useMediaQuery from '@mui/material/useMediaQuery';

import GradeIcon from '@mui/icons-material/Grade';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import { Outlet } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom'
import { BrowserRouter, useLocation } from 'react-router-dom';

import PricingPopup from '../account/pricing/PricingPopUp';
import { API } from '../api/index';

import useAxios from '../api/useAxiosPrivate';
import AuthContext from '../context/AuthContext';


const CompanyHome = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {id} = useParams();
  const axiosPrivate = useAxios();

  const location = useLocation();
  const currentPath = location.pathname;
  const isSmallScreen = useMediaQuery('(max-width:800px)');


  const [error, setError] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyStockPrice, setCompanyStockPrice] = useState();
  const [companyMarketCap, setCompanyMarketCap] = useState();

  let {user, setUser, authToken, setAuthToken} = useContext(AuthContext)


  const [text, setText] = useState('');
  const [count, setCount] = useState(0);
  const [favList, setFavList] = useState([]);

  const [premStatus, setPremStatus] = useState(null);

  const [isPricingOpen, setIsPricingOpen] = useState(false);

  const handleOpenPricing = () => {
    setIsPricingOpen(true);
  };

  const handleClosePricing = () => {
    setIsPricingOpen(false);
  };

  useEffect(() => {
    const fetchPrivateData = async () => {
      const config = {
        header: {
          "Content-Type": "application/json",
        },
      };
      try {
        const { data } = await axiosPrivate.get("/api/auth/authorize", config);   
        setPremStatus(data.status);
      } catch (error) {
        setPremStatus(false);
      }
      setPremStatus(true)
    };
    setPremStatus(true)
    fetchPrivateData();
  }, []); 


  const toggleFavorite = async (favorite, addOnly) => {

    if(favorite === ""){
        return
    }

    /*if(premStatus === false){
      navigate("/pricing");
      return
    }*/
    if(!user){
      navigate("/login");
      return
    }

    favorite = favorite.toUpperCase()
    let addOrToggle = ""
    if(addOnly === true){
        addOrToggle = "addfav"
    }
    else{
        addOrToggle ="favorite"
    }
  const config = {
    header: {
      "Content-Type": "application/json",
    },
  };
  let id = favorite
 
  try {
    const {data} = await API.post("/api/data/quote", { id }, config, { withCredentials: true })
    //console.log(data)
    if(data.length === 0){
    }
    else{
        await axiosPrivate.post(`/api/data/${addOrToggle}`, {favorite}, config, { withCredentials: true });  
        setCount(count + 1); 
    }

    setText("")
  } catch (error) {

    console.log(error)
    setText("")
  }
};



useEffect(() => {
  const fetchPrivateData = async () => {
    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };
    try {
      const { data } = await axiosPrivate.get("/api/data/favlist", config, { withCredentials: true });   
      //console.log(data);
      setFavList(data);
      
      //console.log(data.status); // Log the updated premStatus here
    } catch (error) {
      setFavList("error");
      console.log(error)
    }
  };

  fetchPrivateData();
}, [count]); 





  useEffect(() => {
    const fetchFinancialData = async () => {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
  
        const [quoteResponse] = await Promise.all([
          API.post("/api/data/quote", { id }, config, { withCredentials: true }),

        ]);
  
        const quote = quoteResponse.data;

 

  
        if (quote && Array.isArray(quote) && quote[0] && quote[0].name !== undefined) {
          setCompanyName(quote[0].name);
        } else {
          setCompanyName("Unable to find Ticker");
        }
        setCompanyStockPrice(quote[0].price);
        setCompanyMarketCap(quote[0].marketCap);
  

  
      } catch (error) {
        setError(error);
      }
    };
  
    fetchFinancialData();
  }, [id]);



  return (
    <>
  <AppBar elevation={0} style={{ paddingTop: '20px', height: theme.spacing(8), background: '#E5E5E5' }} position="static">
    <Toolbar>
      <Grid container alignItems="center" justifyContent="space-between" style={{ margin: theme.spacing(2) }}>
        {companyName && !isSmallScreen ? (
          <Grid item>
            <Typography variant="h6" style={{ color: 'black', fontFamily: 'Roboto Mono' }}> {companyName} - {id.toUpperCase()} 
            {favList.includes(id.toUpperCase()) ? (
                <GradeIcon  onClick={() => toggleFavorite(id.toUpperCase())} style={{ cursor: 'pointer', color: 'red', fontSize: '30px', marginLeft: '30px',verticalAlign: 'top' }} />
              ) : (
              <StarOutlineIcon  onClick={() => toggleFavorite(id.toUpperCase())}style={{ cursor: 'pointer',  color: 'red', fontSize: '30px', marginLeft: '30px',verticalAlign: 'top' }} />
            )}
            </Typography>
          </Grid>
        ) : (
          <Grid item>
            <Typography variant="h6" style={{ color: 'black', fontFamily: 'Roboto Mono' }}> {id.toUpperCase()} 
            {favList.includes(id.toUpperCase()) ? (
                <GradeIcon  onClick={() => toggleFavorite(id.toUpperCase())} style={{ cursor: 'pointer', color: 'red', fontSize: '20px', marginLeft: '10px',verticalAlign: 'middle', marginBottom:"2px" }} />
              ) : (
              <StarOutlineIcon  onClick={() => toggleFavorite(id.toUpperCase())}style={{ cursor: 'pointer',  color: 'red', fontSize: '20px', marginLeft: '10px',verticalAlign: 'middle', marginBottom:"2px" }} />
            )}
            </Typography>
          </Grid>
          
        )}

        <Grid item>
          {companyStockPrice && companyMarketCap ? (
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <Typography variant="subtitle1" style={{ color: 'black',  fontSize: isSmallScreen ? '10px' : '14px', fontFamily: 'Roboto Mono' }}> Market Cap </Typography>
                <Typography variant="h5" style={{ color: 'black',  fontSize: isSmallScreen ? '15px' : '30px', fontFamily: 'Roboto Mono' }}> {abbreviateNumber(companyMarketCap)}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" style={{ color: 'black', fontSize: isSmallScreen ? '10px' : '14px',  fontFamily: 'Roboto Mono' }}>Stock Price</Typography>
                <Typography variant="h5" style={{ color: 'black',  fontSize: isSmallScreen ? '15px' : '30px', fontFamily: 'Roboto Mono' }}>{abbreviateNumber(companyStockPrice)}</Typography>
              </Grid>
            </Grid>
          ) : (
            <div></div>
          )}
        </Grid>
      </Grid>
    </Toolbar>
  </AppBar>

    <AppBar elevation={0} style={{ height: theme.spacing(8), background: '#E5E5E5' }} position="static">
        <Toolbar>
          <Grid container alignItems="center" justifyContent="center" spacing={1} style={{ margin: theme.spacing(2) }}>

            <Grid item>
              <Typography component={Link} to={`/company/${id}`} style={{ color: currentPath === `/company/${id}` ? 'black' : 'grey', fontFamily: 'Roboto Mono', display: 'flex', textDecoration: 'none', fontSize: '15px',fontWeight: 'bold',marginLeft: '-20%',border: 'none',}}> Financials</Typography>
            </Grid>

            <Grid item>
            {premStatus === false ? (
              <>
              <Typography onClick={() => handleOpenPricing()} style={{cursor: 'pointer', color: currentPath === `/company/${id}/news` ? 'black' : 'grey',fontFamily: 'Roboto Mono',display: 'flex',textDecoration: 'none',fontSize: '15px',fontWeight: 'bold',marginLeft: '0%',border: 'none',}}> News</Typography>
              <PricingPopup isOpen={isPricingOpen} onClose={handleClosePricing} />
              </>
               ) : (
                <>
                <Typography component={Link} to={`/company/${id}/news`}style={{cursor: 'pointer', color: currentPath === `/company/${id}/news` ? 'black' : 'grey', fontFamily: 'Roboto Mono',display: 'flex',textDecoration: 'none',fontSize: '15px',fontWeight: 'bold',marginLeft: '0%',border: 'none',}}>News</Typography>
                </>
              )}
            </Grid>

            <Grid item>
            {premStatus === false ? (
              <>
                <Typography onClick={() => handleOpenPricing()}  style={{cursor: 'pointer',color: currentPath === `/company/${id}/stock` ? 'black' : 'grey', fontFamily: 'Roboto Mono',display: 'flex',textDecoration: 'none',fontSize: '15px',fontWeight: 'bold',marginLeft: '40%',border: 'none',}}>Stock</Typography>
                <PricingPopup isOpen={isPricingOpen} onClose={handleClosePricing} />
                </>
              ) : (
                <>
              <Typography component={Link} to={`/company/${id}/stock`}style={{color: currentPath === `/company/${id}/stock` ? 'black' : 'grey', fontFamily: 'Roboto Mono',display: 'flex',textDecoration: 'none',fontSize: '15px',fontWeight: 'bold',marginLeft: '40%',border: 'none',}}>Stock</Typography>
              </>
              )}
            </Grid>

          </Grid>
        </Toolbar>
      </AppBar>
    

        <Outlet />

        </>

    );
  };
    
export default CompanyHome;
    