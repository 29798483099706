import { createContext, useState, useEffect } from 'react'
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import React from 'react';
import axios from "axios"
//import Cookies from 'universal-cookie';
import useAxios from '../api/useAxiosPrivate';
import { API } from '../api';
const AuthContext = createContext()

export default AuthContext;
    //two renders are from account symbol and nav bar. one is from itself if you console log under

export const AuthProvider = ({children}) => {

    //const API = axios.create({ baseURL: 'https://app.chartingfs.com/'})

    let [authToken, setAuthToken] = useState(()=> localStorage.getItem('authToken') ? localStorage.getItem('authToken') : null)         // if you can get item, get the item, if you cant, null
    let [user, setUser] = useState(()=> localStorage.getItem('authToken') ? jwtDecode(localStorage.getItem('authToken')) : null)
    //let [premium, setPremium] = useState(()=> localStorage.getItem('authToken') ? jwt_decode(localStorage.getItem('authToken')).premium : false)
    //console.log(user.premium)
    //console.log(localStorage)
    // console.log(jwt_decode(localStorage.getItem('authToken')))
    let [loading, setLoading] = useState(true)
    const navigate = useNavigate();


    let logoutUser = async () => {
        const { data } = await API.post("/api/auth/logout", { withCredentials: true });
        setAuthToken(null)
        setUser(null)
        localStorage.removeItem('authToken')
        navigate('/login')
        //window.location.reload();
    }


    let contextData = {
        user:user,
        authTokens:authToken,
        setAuthToken:setAuthToken,
        setUser:setUser,
        //loginUser:loginUser,
        logoutUser:logoutUser,
    }


    useEffect(()=> {
        if(authToken){
            setUser(jwtDecode(authToken)) //only problem with this is that is refreshes token too  much, but its not doing it anymore
        }
        setLoading(false)

    }, [authToken, loading])

















    return(
        <AuthContext.Provider value={contextData} >
            {loading ? null : children}
        </AuthContext.Provider>
    )
}



/*
    /*useEffect(()=> {                // state change  every run     if you add usecontext, the access tokens starts updating without any refresh? it runs every time usecontext is loaded
        if(authToken){       //if auth token CHANGES? initial start issue
            //data = await refresh() 
            setUser(jwt_decode(authToken))
            console.log('mooga?')
        }
        setLoading(false)

    }, [authToken, loading])*/

/*

    useEffect(()=> {  
        async function refresh1() {
            const {data} = await refresh()
            console.log(data)
            setAuthToken(data.accessToken)
            setUser(jwt_decode(data.accessToken))
            localStorage.setItem("authToken", data.accessToken);
            console.log("googa") 
          }   
             
        if(authToken){    //which it always is

            let exp = (jwt_decode(authToken)).exp
            console.log("when?")
            if (Date.now() >= exp * 1000) {          //if expired            
                                                 //, refresh
                                                 
                //return false;
                refresh1()
            }



            //console.log(jwt_decode(authToken))
            //setUser(jwt_decode(authToken))                              //this is the line of code that for some reason resets the localstorage

            //setUser(jwt_decode(localStorage.getItem('authToken'))) 
            console.log("mooga")
        setLoading(false)
        }
    }, [authToken, loading])               //this runs on initial wrap around app, this runs on initial usecontext, and whenever authtoken is changed from outside(eg, login)

    useEffect(()=> {  
        console.log("test")
    }, [])   

    //HOW TO CHECK THE VALIDITY OF A TOKEN?




    //this will run twice every run, one for in app the wrapper, and one for (useContext)
    //AUTH TOKEN DOES NOT RESET ANYMORE, YOU FIXED THAT BUG, IT CAN ONLY EXPIRE

*/