import { useState, useEffect } from "react";
import axios from "axios";
import { Link as Link2} from "react-router-dom";
import React, { Component, useContext }  from 'react';
import "./RegisterScreen.css";

import { jwtDecode } from "jwt-decode";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { API } from '../api';
import { useNavigate } from "react-router-dom";
import AuthContext from '../context/AuthContext'

const RegisterScreen = ({ history }) => {

  let {user, setUser, authToken, setAuthToken} = useContext(AuthContext)
  const theme = createTheme();
  const navigate = useNavigate();
  //const API = axios.create({ baseURL: 'https://app.chartingfs.com/'})

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");


    useEffect(() => {
    if (localStorage.getItem("authToken")) {
      //console.log("true")
      navigate("/");
    }
  }, []);             //might change to protected route

  const registerHandler = async (e) => {
    e.preventDefault();

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    if (password !== confirmpassword) {
      setPassword("");
      setConfirmPassword("");
      setTimeout(() => {
        setError("");
      }, 5000);
      return setError("Passwords do not match");
    }

    try {
      const { data } = await API.post(
        "/api/auth/register",
        {
          //name,
          email,
          password,
        },
        config, { withCredentials: true }
      );

      
      localStorage.setItem("authToken", data.accessToken);    
      setAuthToken(data.accessToken)
      setUser(jwtDecode(data.accessToken))
      navigate("/");
    } catch (error) {
      setError(error.response.data.error);
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };








  function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://www.chartingfs.com/">
          ChartingFS
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }


















  return (
  

    
    <ThemeProvider theme={theme} >
      <Container component="main" maxWidth="xs"   >

        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" onSubmit={registerHandler}  sx={{ mt: 3 }}>
            <Grid container spacing={2}>
 

              {/*<Grid item xs={12}>
                <TextField
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  //autoComplete="name"
                  autoComplete="off"
                  type="name"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
        */}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  //autoComplete="email"
                  autoComplete="off"
                  type="email"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  //autoComplete="true"
                  autoComplete="off"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="Confirm password"
                  label="Confirm Password"
                  type="password"
                  id="confirmpassword"
                  //autoComplete="true"
                  autoComplete="off"
                  placeholder="Confirm password"
                  value={confirmpassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Grid>
              <br></br><br></br><br></br><br></br><br></br>
              {error && <Typography variant="body1" color="error">{error}</Typography>}
              {/*<Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
        </Grid>*/}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link2 variant="body2" to="/login">Already have an account? Sign in </Link2>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>

  );
};

export default RegisterScreen;
