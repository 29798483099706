import { useState, useEffect } from "react";
import axios from "axios";
import { Link as Link2 } from "react-router-dom";
import React, { Component, useContext }  from 'react';
import "./LoginScreen.css";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
//import Cookies from 'universal-cookie';
import AuthContext from '../context/AuthContext'
import { API } from '../api';
import { jwtDecode } from "jwt-decode";






const LoginScreen = ({ history }) => {

  let {user, setUser, authToken, setAuthToken} = useContext(AuthContext)

  const navigate = useNavigate();
  const theme = createTheme();
  // /const API = axios.create({ baseURL: 'https://app.chartingfs.com/'})

  useEffect(() => {
    if (localStorage.getItem("authToken")) {
      //console.log("true")
      navigate("/");
    }
  }, []);             //might change to protected route



  function Copyright(props) {
    return (
      <Typography variant="body2" color="text.secondary" align="center" {...props}>
        {'Copyright © '}
        <Link color="inherit" href="https://www.chartingfs.com/">
          ChartingFS
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    );
  }



  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");



  const loginHandler = async (e) => {
    e.preventDefault();

    const config = {
      header: {
        "Content-Type": "application/json",
      },
    };

    try {
      const { data } = await API.post(
        "/api/auth/login",
        { email, password }, { withCredentials: true },
        config
      );
        //console.log(data.accessToken)
      localStorage.setItem("authToken", data.accessToken);
      setAuthToken(data.accessToken)
      setUser(jwtDecode(data.accessToken))
      navigate("/");
      //window.location.reload();

    } catch (error) {
      setError(error.response.data.error);
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  return (





      <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" >
      
        <Box
          sx={{
            marginTop: 5,

            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box component="form"   onSubmit={loginHandler}  sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              //autoComplete="email"
              autoComplete="off"
              //autoFocus


              type="email"
        
    
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              tabIndex={1}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
   



    
       
       
              //autoComplete="true"
              autoComplete="off"
              placeholder="Enter password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              tabIndex={2}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
           
            {error && <Typography variant="body1" color="error">{error}</Typography>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Log In
            </Button>
            <Grid container>
              <Grid item xs>
             

             
                <Link2 to="/forgotpassword" variant="body2">
                  Forgot password?
                </Link2>
              </Grid>
              <Grid item>
               <Link2 to="/register" variant="body2"> Don't have an account? Sign up</Link2>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>

  );
};

export default LoginScreen;
