

import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Input, Slide, Button, IconButton, InputAdornment, ClickAwayListener, Divider } from '@mui/material';
// utils
import { alpha } from '@mui/material/styles';
// component
import ListIcon from '@mui/icons-material/List';
import SearchIcon from '@mui/icons-material/Search';
import React, { Component }  from 'react';
// ----------------------------------------------------------------------
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Link, useNavigate } from 'react-router-dom';


export function bgBlur(props) {
  const color =  '#ffffff';
  const blur =  6;
  const opacity = 0.8;


  return {
    backdropFilter: `blur(${blur}px)`,
    WebkitBackdropFilter: `blur(${blur}px)`,
    backgroundColor: alpha(color, opacity),
  };
}

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 92;

const StyledSearchbar = styled('div')(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default}),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: HEADER_MOBILE,
  padding: theme.spacing(0, 3),
  [theme.breakpoints.up('md')]: {
    height: HEADER_DESKTOP,
    padding: theme.spacing(0, 3),
  },
}));
//            <SearchIcon sx={{ color: 'black', width: 25, height: 25 }} onClick={handleOpen} > </SearchIcon>
// ----------------------------------------------------------------------

export default function Searchbar() {
  const [open, setOpen] = useState(false);
  const [ticker, setTicker] = useState('');
  const navigate = useNavigate(); // Use useNavigate hook


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setTicker(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Use the 'ticker' value to construct the URL you want to navigate to
    const url = `/company/${ticker}`;
    // Use navigate to navigate programmatically
    navigate(url);
    // Close the slide after submitting
    handleClose();
    setTicker(''); // Clear the input field
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!open && (
          <IconButton onClick={handleOpen}>
            <SearchIcon sx={{ color: 'black', width: 35, height: 35 }} />
          </IconButton>
        )}

        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <StyledSearchbar>
            <Grid component="form" onSubmit={handleSubmit}>
              <Input
                autoFocus
                fullWidth
                disableUnderline
                placeholder="Search Ticker... eg. 'AAPL'"
                value={ticker}
                onChange={handleChange}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'black', width: 20, height: 20 }} />
                  </InputAdornment>
                }
                sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
              />
              </Grid> 
              <Button
            onClick={handleSubmit} // Use onClick to trigger handleSubmit
            sx={{
              mr: 1,
              fontWeight: 'fontWeightBold',
              fontSize: 13,
              backgroundColor: '#333333',
              '&:hover': { backgroundColor: '#333333' },
            }}
            variant="contained"
            type="button" // Use type="button" to prevent form submission
          >
            Search
          </Button>
            
          </StyledSearchbar>
        </Slide>
      </div>
    </ClickAwayListener>
  );
}
